@font-face {
  font-family: gilroyregular;
  src: url(./static/fonts/gilroy/Gilroy-Regular.ttf);
}

@font-face {
  font-family: gilroymedium;
  src: url(./static/fonts/gilroy/Gilroy-Medium.ttf);
}

@font-face {
  font-family: gilroysemibold;
  src: url(./static/fonts/gilroy/Gilroy-SemiBold.ttf);
}

@font-face {
  font-family: gilroybold;
  src: url(./static/fonts/gilroy/Gilroy-Bold.ttf);
}

@font-face {
  font-family: gilroyextrabold;
  src: url(./static/fonts/gilroy/Gilroy-ExtraBold.ttf);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: gilroymedium;
  letter-spacing: 0.05vw;
}

html, body{
  overflow-x: hidden;
}