.navbar {
    height: 12vh;
    width: 100vw;
    /* background-color: rgb(14, 14, 14); */

    padding: 0vh 5vw;

    display: flex;
    align-items: center;
    justify-content: space-between;

    position: absolute;
    top: 2vh;
    z-index: 1;
}

.glass_effect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;
    padding: 3vh 3vw;

    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(7px);
    border-radius: 1vw;
}

.pages_links h6 {
    color: white;
    text-decoration: none;
    font-size: 1vw;
    font-family: gilroyregular;
    letter-spacing: .05vw;
}

.pages_links {
    display: flex;
    gap: 4vw;
}

.logo img {
    width: 8vw;
}

.quote_btn button {
    background-color: white;
    border: none;
    padding: .8vh 2vw;
    cursor: pointer;
    font-family: gilroysemibold;
}