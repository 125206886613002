.hero_section {
    height: 99vh;
    width: 100vw;
    background-color: #000000;
    overflow: hidden;

    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}

.hero_section img {
    width: 100vw;
}

.main_hero_part {
    height: 99vh;
    width: 100vw;
    background-color: rgba(46, 46, 46, 0.403);

    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
}

.main_hero_part h2 {
    font-size: 3.5vw;
    font-family: gilroyextrabold;
    color: white;
    text-shadow: 0px 10.349px 25.503px rgba(0, 0, 0, 0.25);

    margin-top: 60vh;
    margin-left: 45vw;
}

footer {
    height: 50vh;
    width: 100vw;
    background-color: rgb(16, 16, 16);
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_left {
    height: 50vh;
    width: 65vw;
    /* background-color: grey; */

    padding: 5vh 1vw 5vh 3vw;
}

.footer_left>h2 {
    color: white;
    margin-top: 9vh;
}

.footer_right {
    height: 50vh;
    width: 35vw;
    /* background-color: rgb(83, 83, 83); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_image_con {
    height: 50vh;
    width: 35vw;
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: end;
}

.footer_image_card {
    width: 17vw;
    height: 43vh;
    border-radius: 25px;
    /* border: 1px solid #FFF; */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 7vw;
}

.footer_image_card img{
    width: 20vw;
    transform: rotate(-5deg);
}

.footer_left_top {
    display: flex;
    align-items: center;
    gap: 13vw;
}

.footer_left_bottom {
    display: flex;
    gap: 8vw;
    margin-top: 1vh;
}

.footer_left_bottom h4 {
    width: 12vw;
    font-size: 2vw;
    padding: 0vh 2vw;
    color: white;
}

.pages_links_footer h6 {
    color: white;
    text-decoration: none;
    font-size: 1.1vw;
    font-family: gilroyregular;
    letter-spacing: .05vw;
}

.pages_links_footer {
    display: flex;
    gap: 4vw;
    margin-top: 3vh;
}

.inquiry_details {
    color: white;
}

.inquiry_details h5 {
    font-size: 1vw;
    letter-spacing: 0.05vw;
}

.inquiry_details h2 {
    margin-top: 2vh;
    letter-spacing: 0.05vw;
}

.inquiry_details h3 {
    letter-spacing: 0.05vw;
    font-size: 1.2vw;
    font-family: gilroyregular;
    margin-top: 1.5vh;
}

.inquiry_details h6 {
    letter-spacing: 0.05vw;
    font-size: 1.3vw;
    font-family: gilroyregular;
}

.footer_right > img {
    width: 22vw;
}

.faqs_section{
    height: 80vh;
    width: 100vw;

    padding: 8vh 12vw;
}

.faqs_section > h4 {
    font-size: 2vw;
    margin-bottom: 5vh;
}

.ques_drop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: inherit;

    margin-bottom: 3vh;
}

.ques_drop h5{
    font-size: 1.2vw;
}

.ques_drop img{
    width: 1.3vw;
    cursor: pointer;
}

.question{
    margin-bottom: 6vh;
}

.question p{
    font-size: 1vw;
    font-family: gilroymedium;
}

.custom_section{
    height: 70vh;
    width: 100vw;

    display: flex;
}

.cus_left{
    height: 70vh;
    width: 50vw;
    background-color: #FFFCF8;

    display: flex;
    align-items: center;
    justify-content: center;
    
}

.cus_right{
    height: 70vh;
    width: 50vw;
    background-color: #F8FDFF;

    display: flex;
    align-items: center;
    justify-content: center;
}

.cnt_left{
    height: 40vh;
    width: 38vw;
    background-color: #fff2dc;

    position: relative;

    border-radius: 1.4vw;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cnt_left h4{
    font-size: 1.7vw;
    font-family: gilroybold;
    margin-top: 14vh;
    margin-bottom: 4vh;
}

.cnt_left p{
    text-align: center;
    width: 36vw;
    margin-bottom: 2vh;
}

.cnt_left img{
    position: absolute;
    top: -35%;
    left: 50%;
    transform: translatey(-50%);
    transform: translatex(-50%);

    width: 12vw;
}

.cnt_right{
    height: 40vh;
    width: 38vw;
    background-color: #DDF5FF;

    position: relative;

    border-radius: 1.4vw;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cnt_right h4{
    font-size: 1.7vw;
    font-family: gilroybold;
    margin-top: 14vh;
    margin-bottom: 4vh;
}

.cnt_right p{
    text-align: center;
    width: 36vw;
    margin-bottom: 2vh;
}

.cnt_right img{
    position: absolute;
    top: -35%;
    left: 50%;
    transform: translatey(-50%);
    transform: translatex(-50%);

    width: 12vw;
}

.footermain{
    display: flex;
    position: absolute;
}